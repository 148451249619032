import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // useParams
} from "react-router-dom";
import Mint from './Mint';

require('react-dom');
window.React2 = require('react');
console.log(window.React1 === window.React2);

export default function Routing() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/:id" component={Mint} />
          <Route path="/" component={Mint} />
        </Switch>
      </div>
    </Router>
  );
}

